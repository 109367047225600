@import url('https://fonts.googleapis.com/css2?family=Barriecito&family=Rubik+80s+Fade&family=Special+Elite&display=swap');


.snake-mobile-buttons>div>button {
    padding: 0px 5px !important;
    height: 40px !important;
    width: 50px !important;
    margin: 2% 5px !important;
    font-size: 0 !important;
    opacity: 0;
}

.snake-mobile-buttons {
    padding-top: 0px !important;
    width: 37% !important;
    background-image: url("arrows.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.point-bar>div, p {

    color: white !important;
    font-family: 'Barriecito' !important;
    font-size: 35px;
}
